* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000;
  font-family: monospace;
}

.site {
  margin: 20px;
}

h1, h2 {
  margin: 20px 0;
}
h2 {
  font-size: 24px;
}
h3 { 
  font-size: 16px;
}

.download-button {
  padding: 6.5px 20px;
  border: 1px solid black;
  background-color: white;
  transition: all 0.5s ease;
}

.download-button:hover {
  background-color: black;
  color: white;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
  max-width: 400px;
  gap: 10px;
}

.section-container {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 400px;
}

.no-margin {
  margin: 0;
}

.link-grid {
  display: grid;
  grid-template-columns: 230px 1fr;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.year {
  font-size: 12px;
  color: #666666;
  font-weight: normal;
}
